import { render, staticRenderFns } from "./CancelledBookingTicket.vue?vue&type=template&id=5a389a64&scoped=true"
import script from "./CancelledBookingTicket.vue?vue&type=script&setup=true&lang=js"
export * from "./CancelledBookingTicket.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CancelledBookingTicket.vue?vue&type=style&index=0&id=5a389a64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a389a64",
  null
  
)

export default component.exports